import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        /*{
            path: '*',
            name: '404',
            component: () => import('./pages/error/404.vue'),
        },*/
        {
            path: '/',
            name: 'home',
            meta:{
                index:0,
                title: "Portfolio"
            },
            component: () => import('./page/index.vue'),
            props: true
        },
        {
            path: '/works/bitpays',
            name: 'bitpays',
            meta:{
                index:1,
                title: "Bitpays"
            },
            component: () => import('./page/work/bitpays.vue'),
            props: true,
        },
        {
            path: '/works/traworld',
            name: 'traworld',
            meta:{
                index:1,
                title: "Traworld Merchant"
            },
            component: () => import('./page/work/traworld.vue'),
            props: true,
        },
        {
            path: '/works/tourplus',
            name: 'tourplus',
            meta:{
                index:1,
                title: "Tourplus"
            },
            component: () => import('./page/work/tourplus.vue'),
            props: true,
        }
        /*{
            path: '/works/trexchain',
            name: 'trexchain',
            meta:{
                index:1,
                title: "TrexChain"
            },
            component: () => import('./page/work/trexchain.vue'),
        },
        {
            path: '/works/mifun',
            name: 'mifun',
            meta:{
                index:1,
                title: "Mifun"
            },
            component: () => import('./page/work/mifun.vue'),
        },
        {
            path: '/works/stuttgart',
            name: 'stuttgart',
            meta:{
                index:1,
                title: "Stuttgart"
            },
            component: () => import('./page/work/stuttgart.vue'),
        },
        {
            path: '/works/wentertechment',
            name: 'wentertechment',
            meta:{
                index:1,
                title: "W Entertechment"
            },
            component: () => import('./page/work/w.vue'),
        },
        {
            path: '/works/cloudtac',
            name: 'cloudtac',
            meta:{
                index:1,
                title: "Cloudtac"
            },
            component: () => import('./page/work/cloudtac.vue'),
        },
        {
            path: '/works/gobao',
            name: 'gobao',
            meta:{
                index:1,
                title: "GoBao"
            },
            component: () => import('./page/work/gobao.vue'),
        },
        {
            path: '/works/bbbsuperapp',
            name: 'bbbsuperapp',
            meta:{
                index:1,
                title: "BBB SuperApp"
            },
            component: () => import('./page/work/bbb.vue'),
        },
        {
            path: '/works/redcliff',
            name: 'redcliff',
            meta:{
                index:1,
                title: "Redcliff"
            },
            component: () => import('./page/work/redcliff.vue'),
        },
        {
            path: '/works/trading',
            name: 'trading',
            meta:{
                index:1,
                title: "Trading"
            },
            component: () => import('./page/work/trading.vue'),
        },
        {
            path: '/works/bluemount',
            name: 'bluemount',
            meta:{
                index:1,
                title: "Trading"
            },
            component: () => import('./page/work/bluemount.vue'),
        },*/
    ],
    scrollBehavior () {
        return { x: 0, y: 0 };
    }
});

router.beforeEach((to, from, next) => {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title
    } else { // ← here ↓
        document.title = previousNearestWithMeta.meta.title
    }
  
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  
    if(!nearestWithMeta) return next();
  
    nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');
  
      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });
  
      tag.setAttribute('data-vue-router-controlled', '');
  
      return tag;
    })

    .forEach(tag => document.head.appendChild(tag));
  
    next();
  });

export default router;
