import Vue from 'vue'
import App from './App.vue'
import router from './router';
//import VueAnalytics from 'vue-analytics';

import './assets/css/style.css'

/*Vue.use(VueAnalytics, {
  id: 'UA-58182230-3',
  router
});*/

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
