<template>
  <div id="app">
    <transition :name="transitionName" >  
      <keep-alive>
        <router-view :key="$route.fullPath" />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  data(){
      return {
        transitionName:null,
        firstLoad: undefined,
      }
  },
  watch: {
    $route(to, from) {
      if(from.name == null){
        this.transitionName = 'fade';
      } else if (to.name){
        this.transitionName = 'fade';
      } else if (to.meta.index > from.meta.index){
        //Setting Animation Name
        this.transitionName = 'slide-left';
      } else {
        this.transitionName = 'slide-right';
      }
    }
  }
}
</script>


<script src="https://www.googletagmanager.com/gtag/js?id=UA-58182230-3"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-58182230-3');
</script>

<style>

</style>
